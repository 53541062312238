:root {
  // ALL COLORS OF ISPUI-SWITCHER v0.3.0-feature-inputv6-component.0
  --ispiu-swtich__button-border: 1px solid var(--isp-light-gray-1);
  --ispiu-swtich__button-bg: var(--isp-white);
  --ispiu-swtich__button-bg-hover: transparent;
  --ispiu-swtich__button-bg_selected: var(--isp-light-blue-1);
  --ispiu-swtich__button-bg_selected-hover: var(--isp-light-blue-1);

  // @WARN there is also dropdown items colors not customable
}
