:root {
  // ALL COLORS OF ISPUI-CHECKBOX v1.3.6-feature-inputv6-component.0
  --ispui-checkbox__font-color: var(--isp-dark-blue-2);
  --ispui-checkbox__border-color: var(--isp-light-gray-3);
  --ispui-checkbox__background-color: var(--isp-white);
  --ispui-checkbox__background-image-icon-empty: "";
  --ispui-checkbox__minus-color-unchecked: transparent;
  --ispui-checkbox__minus-background-color-unchecked: transparent;

  // checked
  --ispui-checkbox__checkbox-color-checked: var(--isp-blue-6);
  --ispui-checkbox__checkbox-color-unchecked: transparent;
  --ispui-checkbox__border-color-checked: var(--isp-blue-6);
  --ispui-checkbox__background-color-checked: var(--isp-white);
  --ispui-checkbox__background-image-icon-checked: var(
    --isp-checkbox-checked-image
  );
  --ispui-checkbox__minus-color-checked: var(--isp-white);
  --ispui-checkbox__minus-background-color-checked: var(--isp-blue-6);
  // disabled
  --ispui-checkbox__checkbox-color-disabled-checked: var(--isp-light-gray-3);
  --ispui-checkbox__checkbox-color-disabled-unchecked: transparent;
  --ispui-checkbox__font-color-disabled: var(--isp-light-gray-3);
  --ispui-checkbox__border-color-disabled: var(--isp-light-gray-3);
  --ispui-checkbox__background-color-disabled: var(--isp-light-gray-6);
  --ispui-checkbox__background-image-icon-disabled: "";
  --ispui-checkbox__background-image-icon-checked-disabled: var(
    --isp-checkbox-checked-image-disabled --ispui-checkbox__minus-color-disabled:
      var(--isp-white) ; --ispui-checkbox__minus-background-color-disabled:
      var(--isp-light-gray-1) ; --ispui-checkbox__minus-color-unchecked-disabled:
      transparent; --ispui-checkbox__minus-background-color-unchecked-disabled:
      transparent;
  );
  // focused
  --ispui-checkbox__focus-border-color: #187abd4d;
}
