body {
  > .isp-layout {
    display: flex;
    width: 100%;
    min-width: max-content;

    > .isp-background-image {
      position: fixed;

      height: 100%;

      z-index: -1;

      background-position-y: top;
    }

    > .isp-background-image_repeat_on {
      background-repeat: repeat;
    }

    > .isp-background-image_repeat_off {
      background-repeat: no-repeat;
    }

    // no stretch at all
    > .isp-background-image_stretch_auto {
      background-size: auto;
    }

    // stretch by verticaly + no stretch by horizontaly. Default
    > .isp-background-image_stretch_verticaly {
      background-size: auto 100%;
    }

    // stretch by no stretch verticaly + stretch by horizontaly
    > .isp-background-image_stretch_horizontaly {
      background-size: 100% auto;
    }

    // stretch by verticaly + stretch by horizontaly
    > .isp-background-image_stretch_full {
      background-size: cover;
    }

    > .isp-page {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      max-width: 700px;
      min-width: 700px;

      background-color: var(--isp-white);
      padding: 0 0 30px 0;

      > .isp-page__top-padding {
        height: 100%;
        min-height: 30px;
        max-height: 200px;

        flex-shrink: 0.95;
      }

      > .isp-page__logo-indent {
        height: 100%;
        min-height: 30px;
        max-height: 130px;

        flex-shrink: 1;
      }
    }

    > .isp-page_shadow {
      box-shadow: -1px 2px 7px rgba(52, 74, 94, 0.56);
    }
  }

  > .isp-layout_left {
    justify-content: flex-start;

    > .isp-background-image {
      background-position-x: left;
      left: 700px;
      width: calc(100% - 700px);
    }
  }

  > .isp-layout_right {
    justify-content: flex-end;

    > .isp-background-image {
      background-position-x: right;
      right: 700px;
      width: calc(100% - 700px);
    }
  }

  > .isp-layout_center {
    justify-content: center;

    > .isp-background-image {
      width: 100%;
    }

    > .isp-page {
      margin: 60px 0 60px 0;
      border-radius: 5px;
    }

    > .isp-page_shadow {
      box-shadow: 0px 1px 4px rgba(52, 74, 94, 0.4);
    }
  }
}
