:root {
  // ALL COLORS OF ISPUI-BUTTON v4.4.0
  --ispui-button__line-height: 33px;

  // general
  --ispui-button__icon-stroke: transparent;
  --ispui-button__icon-fill: transparent;
  --ispui-button__icon-stroke_hover: transparent;
  --ispui-button__icon-fill_hover: transparent;
  --ispui-button__icon-stroke_active: transparent;
  --ispui-button__icon-fill_active: transparent;

  // general disabled state
  --ispui-button__inactive_text_regular: var(--isp-light-gray-3);
  --ispui-button__inactive_background_regular: var(--isp-light-gray-6);
  --ispui-button__inactive-icon-stroke: var(--isp-light-gray-3);
  --ispui-button__inactive-icon-fill: transparent;
  // hover
  --ispui-button__inactive_text_hover: var(--isp-light-gray-3);
  --ispui-button__inactive_background_hover: var(--isp-light-gray-6);
  --ispui-button__inactive-icon-stroke_hover: var(--isp-light-gray-3);
  --ispui-button__inactive-icon-fill_hover: transparent;
  // active
  --ispui-button__inactive_text_active: var(--isp-light-gray-3);
  --ispui-button__inactive_background_active: var(--isp-light-gray-6);
  --ispui-button__inactive-icon-stroke_active: var(--isp-light-gray-3);
  --ispui-button__inactive-icon-fill_active: transparent;

  // accent theme
  --ispui-button__accent_text_regular: var(--isp-dark-blue-2);
  --ispui-button__accent_border_regular: transparent;
  --ispui-button__accent_background_regular: var(--isp-yellow-7);
  // hover
  --ispui-button__accent_text_hover: var(--isp-dark-blue-2);
  --ispui-button__accent_border_hover: transparent;
  --ispui-button__accent_background_hover: var(--isp-yellow-8);
  // active
  --ispui-button__accent_text_active: var(--isp-dark-blue-2);
  --ispui-button__accent_border_active: transparent;
  --ispui-button__accent_background_active: var(--isp-yellow-9);

  // ispui-button third theme don't used in our interface!
  --ispui-button__third_text_regular: var(--isp-white);
  --ispui-button__third_border_regular: var(--isp-white);
  --ispui-button__third_background_regular: rgba(255, 255, 255, 0.5);
  // hover
  --ispui-button__third_text_hover: var(--isp-white);
  --ispui-button__third_border_hover: var(--isp-white);
  --ispui-button__third_background_hover: rgba(255, 255, 255, 0.5);
  // active
  --ispui-button__third_text_active: var(--isp-white);
  --ispui-button__third_border_active: var(--isp-white);
  --ispui-button__third_background_active: rgba(255, 255, 255, 0.5);

  // fourth theme. Overrided in form-button and ispui-select
  // --ispui-button__fourth_text_regular: var(--isp-light-gray-6);
  // --ispui-button__fourth_border_regular: transparent;
  // --ispui-button__fourth_background_regular: transparent;
  // // hover
  // --ispui-button__fourth_text_hover: var(--isp-yellow-7);
  // --ispui-button__fourth_border_hover: transparent;
  // --ispui-button__fourth_background_hover: transparent;
  // // active
  // --ispui-button__fourth_text_active: var(--isp-yellow-5);
  // --ispui-button__fourth_border_active: transparent;
  // --ispui-button__fourth_background_active: transparent;

  // primary theme
  --ispui-button__primary_text_regular: var(--isp-white-4);
  --ispui-button__primary_border_regular: transparent;
  --ispui-button__primary_background_regular: var(--isp-blue-6);
  // hover
  --ispui-button__primary_text_hover: var(--isp-white-4);
  --ispui-button__primary_border_hover: transparent;
  --ispui-button__primary_background_hover: var(--isp-blue-8);
  // active
  --ispui-button__primary_text_active: var(--isp-white-4);
  --ispui-button__primary_border_active: transparent;
  --ispui-button__primary_background_active: var(--isp-blue-9);

  // ispui-button secondary theme don't used in our interface!
  // --ispui-button__secondary_text_regular: var(--isp-dark-blue-2);
  // --ispui-button__secondary_border_regular: transparent;
  // --ispui-button__secondary_background_regular: var(--isp-light-blue-10);
  // // hover
  // --ispui-button__secondary_text_hover: var(--isp-dark-blue-2);
  // --ispui-button__secondary_border_hover: transparent;
  // --ispui-button__secondary_background_hover: #eaf7ff;
  // // active
  // --ispui-button__secondary_text_active: var(--isp-dark-blue-2);
  // --ispui-button__secondary_border_active: transparent;
  // --ispui-button__secondary_background_active: #8ed5ff;

  // ispui-button fifth theme don't used in our interface!
  // --ispui-button__fifth_text_regular: #282828;
  // --ispui-button__fifth_border_regular: #282828;
  // --ispui-button__fifth_background_regular: var(--isp-white);
  // // hover
  // --ispui-button__fifth_text_hover: var(--isp-white);
  // --ispui-button__fifth_border_hover: #282828;
  // --ispui-button__fifth_background_hover: #282828;
  // --ispui-button__fifth_text_active: var(--isp-light-gray-1);
  // // active
  // --ispui-button__fifth_border_active: #282828;
  // --ispui-button__fifth_background_active: #3f3d3d;
  // // focus
  // --ispui-button__fifth_text_focus: var(--isp-white);
  // --ispui-button__fifth_border_focus: #282828;
  // --ispui-button__fifth_background_focus: #282828;

  // sixth theme
  --ispui-button__sixth_text_regular: var(--isp-blue-6);
  --ispui-button__sixth_border_regular: var(--isp-blue-6);
  --ispui-button__sixth_background: transparent;
  // hover
  --ispui-button__sixth_text_hover: var(--isp-blue-8);
  --ispui-button__sixth_border_hover: var(--isp-blue-8);
  // active
  --ispui-button__sixth_text_active: var(--isp-blue-9);
  --ispui-button__sixth_border_active: var(--isp-blue-9);
  // disabled
  --ispui-button__sixth_text_disabled: var(--isp-light-gray-3);
  --ispui-button__sixth_border_disabled: var(--isp-light-gray-1);
  --ispui-button__sixth_text_disabled_hover: var(--isp-light-gray-3);
  --ispui-button__sixth_border_disabled_hover: var(--isp-light-gray-1);

  // @WARN ispui-button have shadow colors, that not customable!
}
