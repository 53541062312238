body {
  display: flex;

  min-height: 100vh;
  margin: 0;
  padding: 0;

  color: var(--isp-dark-blue-2);

  font-family: "Ubuntu", sans-serif;

  overflow: auto;
}
