:root {
  --ispui-table-footer__background: var(--isp-white);
  --ispui-table-footer__color: var(--isp-text-color-main);
  --ispui-table__theme-light-color: var(--isp-light-gray-3);
  --ispui-table__theme-light-bg: var(--isp-white);
  --ispui-table__theme-neutral-color: var(--isp-light-gray-3);
  --ispui-table__theme-neutral-bg: var(--isp-light-gray-6);
  --ispui-table__theme-dark-color: var(--isp-light-gray-6);
  --ispui-table__theme-dark-bg: var(--isp-dark-blue-2);
  --ispui-table__border: 1px solid var(--isp-light-gray-1);
  --ispui-table__background: var(--isp-white);
  --ispui-table-checkbox__border: 1px solid var(--isp-light-gray-3);
  --ispui-table-resizer__lever-color: var(--isp-light-gray-1);
  --ispui-table-kebab__hover-bg: var(--isp-grey-opacity-2);
  --ispui-table-gap-container__gap-bg: var(--isp-white);
  --ispui-table-checkbox__minus-border-color: var(--isp-blue-6);
  --ispui-table-checkbox__minus-bg-color: var(--isp-blue-6);
  --ispui-table-checkbox__checked-border-color: var(--isp-blue-6);
  --ispui-table-card__bg: var(--isp-white);
  --ispui-table-card__box-shadow: 0 1px 10px 0 var(--isp-dark-blue-2);
  --ispui-table-card__color: var(--isp-dark-blue-2);
  --ispui-table-card__marker-bg: var(--isp-blue-6);
  --ispui-table-card__border-bottom: 1px solid var(--isp-light-gray-1);
  --ispui-table__text-color: var(--isp-dark-blue-2);
  --ispui-table__text-color-disabled: var(--isp-light-gray-3);
  --ispui-table__additional-text-color: var(--isp-light-gray-3);
  --ispui-table__action-text-color: var(--isp-blue-6);
  --ispui-table__row-disabled-color: var(--isp-light-gray-3);
  --ispui-table__row-disabled-bg: var(--isp-light-gray-6);
  --ispui-table__action-text-error-color: var(--isp-pink-8);
  --ispui-table__chosen-row-marker-border: 4px solid var(--isp-blue-6);
  --ispui-table-pagination__size-color: var(--isp-blue-6);
  --ispui-table-pagination__btn-icon-color: var(--isp-blue-6);
  --ispui-table-pagination__size-arrow-color: var(--isp-blue-6);
  --ispui-table-pagination__size-var-hover-bg: var(--isp-light-blue-1);
  --ispui-table-config__icon-color: var(--isp-blue-6);
  .ispui-table-checkbox_disabled {
    background-color: var(--isp-light-gray-6);
  }
  .ispui-table-gap-container__gap_no-data {
    &::after {
      color: var(--isp-dark-blue-2);
    }
  }

  .ispui-table__row {
    --ispui-table__row-bg: var(--isp-light-gray-6);
    --ispui-table__row-hover-bg: var(--isp-light-blue-12);
    --ispui-table__row-chosen-bg: var(--isp-light-blue-11);

    &:nth-child(2n) {
      --ispui-table__row-bg: var(--isp-white);
      --ispui-table__row-chosen-bg: var(--isp-light-blue-13);
    }
  }
}
