/** overrides for ant date-picker */
.ant-picker {
  height: 30px;
  padding: 0 10px 0 10px;

  border-color: var(--isp-date-picker-border-color, var(--isp-light-gray-1));
}

.ant-picker-header {
  color: var(--isp-blue-6);
}

.ant-picker-header-view button:hover {
  color: var(--isp-blue-8);
}

.ant-picker-header-view button:active {
  color: var(--isp-blue-9);
}

.ant-picker-wrapper button {
  font-family: "Ubuntu", sans-serif;
}

.ant-picker-input > input {
  font-family: "Ubuntu", sans-serif;
}

.ant-picker-focused {
  border-color: var(--isp-date-picker-border-color, var(--isp-blue-6));
}

.ant-picker:hover:not(.ant-picker-focused) {
  border-color: var(--isp-date-picker-border-color, var(--isp-light-gray-1));
}

.ant-picker:hover.ant-picker-focused {
  border-color: var(--isp-date-picker-border-color, var(--isp-blue-6));
}

.ant-picker.ant-picker-disabled {
  cursor: default;

  border-color: var(--isp-date-picker-border-color, var(--isp-light-gray-1));
  background-color: var(
    --ispui-input__background-color_disabled,
    var(--isp-light-gray-6)
  );
}

.ant-picker-input > input[disabled] {
  cursor: default;

  color: var(--ispui-input__text-color_disabled, var(--isp-light-gray-3));

  background: var(--ispui-input__background-color_disabled);
}

.ant-picker-today-btn {
  cursor: pointer;

  color: var(--isp-blue-6);
}

.ant-picker-today-btn:hover {
  color: var(--isp-blue-8);
}

.ant-picker-today-btn:active {
  color: var(--isp-blue-9);
}

.ant-picker-clear {
  top: 55%;

  display: grid;

  width: 20px;
  height: 30px;

  place-items: center;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--isp-blue-6);
}
