:root {
  // ALL COLORS OF ISPUI-INPUT v3.6.2-feature-focus-options.2
  // ispui-input
  --ispui-input__border-color: var(--isp-light-gray-1);
  --ispui-input__border-color_active: var(--isp-blue-6);
  --ispui-input__text-color: var(--isp-dark-blue-2);
  --ispui-input__text-color_disabled: var(--isp-light-gray-3);
  --ispui-input__label-color: var(--isp-gray-4);
  --ispui-input__background-color_disabled: var(--isp-light-gray-6);
  --ispui-input__background-color: var(--isp-white);
  --ispui-input__accent-color: var(--isp-pink-8);
  --ispui-input__active-color: var(--isp-blue-6);

  --ispui-input__prefix-color: var(--isp-light-gray-3);
  --ispui-input__prefix-color_disabled: var(--isp-light-gray-3);

  // ispui-input clear button colors
  --ispui-input_regular__clear-button-background-color: var(--isp-gray-4);
  --ispui-input_regular__clear-button-background-color_hover: #626262;
  --ispui-input_regular__clear-button-background-color_active: var(
    --isp-light-gray-3
  );
  --ispui-input_regular__clear-button-cross-color: var(--isp-white);
  --ispui-input_regular__clear-button-cross-color_hover: var(--isp-white);
  --ispui-input_regular__clear-button-cross-color_active: var(--isp-white);
  --ispui-input_invalid__clear-button-background-color: var(--isp-pink-8);
  --ispui-input_invalid__clear-button-background-color_hover: var(
    --isp-pink-11
  );
  --ispui-input_invalid__clear-button-background-color_active: var(
    --isp-pink-7
  );
  --ispui-input_invalid__clear-button-cross-color: var(--isp-white);
  --ispui-input_invalid__clear-button-cross-color_hover: var(--isp-white);
  --ispui-input_invalid__clear-button-cross-color_active: var(--isp-white);
  --ispui-input_disabled__clear-button-background-color: var(
    --isp-light-gray-3
  );
  --ispui-input_disabled__clear-button-background-color_hover: var(
    --isp-light-gray-3
  );
  --ispui-input_disabled__clear-button-background-color_active: var(
    --isp-light-gray-3
  );
  --ispui-input_disabled__clear-button-cross-color: var(--isp-white);
  --ispui-input_disabled__clear-button-cross-color_hover: var(--isp-white);
  --ispui-input_disabled__clear-button-cross-color_active: var(--isp-white);
  --ispui-input_focused__clear-button-background-color: var(--isp-blue-6);
  --ispui-input_focused__clear-button-background-color_hover: var(--isp-blue-8);
  --ispui-input_focused__clear-button-background-color_active: var(
    --isp-blue-9
  );
  --ispui-input_focused__clear-button-cross-color: var(--isp-white);
  --ispui-input_focused__clear-button-cross-color_hover: var(--isp-white);
  --ispui-input_focused__clear-button-cross-color_active: var(--isp-white);

  // ispui-input validation icon
  --ispui-input-validation-icon-background-color_valid: var(--isp-green-3);
  --ispui-input-validation-icon-stroke-color_valid: var(--isp-white);
  --ispui-input-validation-icon-background-color_invalid: var(--isp-pink-8);
  --ispui-input-validation-icon-stroke-color_invalid: var(--isp-white);

  // ispui-textarea scrollbar
  --ispui-scrollbar-thumb-color: var(--isp-dark-blue-2);
  --ispui-scrollbar-background-color: var(--isp-light-gray-6);

  // ispui-password
  --ispui-password-icon-color: var(--isp-light-gray-3);
}
