:root {
  // ALL COLORS OF ISPUI-LINK v5.0.4
  --ispui-link__color-additional: var(--isp-light-gray-3);
  --ispui-color__additional-text: var(--isp-light-gray-3);
  --ispui-link__color_fail: var(--isp-pink-8);
  --ispui-link__color_fail_hover: var(--isp-pink-11);
  --ispui-link__color_fail_active: var(--isp-pink-7);
  --ispui-link__color_inverse: #f8e71c;
  --ispui-link__color_inverse_hover: #fff680;
  --ispui-link__color_inverse_active: #ffd100;
  --ispui-link__color_regular: var(--isp-blue-6);
  --ispui-link__color_hover: var(--isp-blue-8);
  --ispui-link__color_active: var(--isp-blue-9);
  --ispui-link__color_inactive: var(--isp-light-gray-3);
  --ispui-link__color_success: var(--isp-green-3);
  --ispui-link__color_success_hover: var(--isp-green-2);
  --ispui-link__color_success_active: var(--isp-green-5);
}
