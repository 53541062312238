:root {
  // ALL COLORS OF ISPUI-CONFIRM v7.0.1
  --ispui-confirm__text-color: var(--isp-dark-blue-2);
  --ispui-confirm__container-bg-color: var(--isp-white);
  --isp-color__modal-border: var(--isp-light-gray-1);
  --ispui-confirm__container-shadow: rgba(52, 74, 94, 0.8);
  --ispui-confirm__overlay-background-color: rgba(52, 74, 94, 0.8);

  @media (min-width: 300px) {
    --ispui-confirm__width-small: 320px;
  }

  @media (min-width: 360px) {
    --ispui-confirm__width-small: 360px;
  }

  @media (min-width: 390px) {
    --ispui-confirm__width-small: 390px;
  }

  @media (min-width: 420px) {
    --ispui-confirm__width-small: 420px;
  }
}
