:root {
  // ALL COLORS OF ISPUI-FILE v1.0.0

  --ispui-file__file-item-name-color: var(--isp-dark-blue-2);
  --ispui-file__item-icon-color: var(--isp-dark-blue-2);
  --ispui-file__link-color: var(--isp-blue-6);
  --ispui-file__link-color_disabled: var(--isp-light-gray-3);
  --ispui-file__link-color_invalid: var(--isp-blue-6);
  --ispui-file__link-hint-color: var(--isp-light-gray-3);
  --ispui-file__link-hint-color_disabled: var(--isp-light-gray-3);
  --ispui-file__link-hint-color_invalid: var(--isp-pink-8);
  --ispui-file__link_default-icon-color: var(--isp-gray-4);
  --ispui-file__remove-icon-color: var(--isp-gray-4);
}
