:root {
  --ispui-toggle-background-color_checked: var(--isp-blue-6);
  --ispui-toggle-border-color_checked: var(--isp-blue-6);
  --ispui-toggle-switch-color_checked: var(--isp-white);
  --ispui-toggle-switch-border-color_checked: var(--isp-white);
  --ispui-toggle-background-color_disabled: var(--isp-white);
  --ispui-toggle-border-color_disabled: var(--isp-light-gray-20);
  --ispui-toggle-switch-color_disabled: var(--isp-light-gray-20);
  --ispui-toggle-switch-border-color_disabled: var(--isp-light-gray-20);
  --ispui-toggle-background-color_checked-disabled: var(--isp-light-gray-20);
  --ispui-toggle-border-color_checked-disabled: var(--isp-light-gray-20);
  --ispui-toggle-switch-color_checked-disabled: var(--isp-white);
  --ispui-toggle-switch-border-color_checked-disabled: var(--isp-white);
  --ispui-toggle__outline-color: rgba(24, 122, 189, 0.3);
  --ispui-toggle__label-color: var(--isp-dark-blue-2);
}
