:root {
  // ALL COLORS OF ISPUI-DROPDOWN v1.6.5-feature-inputv6-component.0
  --ispui-dropdown-bg-color: var(--isp-white);
  --ispui-dropdown-popup__container_box-shadow: -1px 2px 7px
    rgba(52, 74, 94, 0.56);
  --ispui-dropdown-scroll-indicator-color: var(--isp-dark-blue-2);
  --ispui-dropdown-scroll-background-color: var(--isp-light-gray-6);
  --ispui-dropdown-min-width: 320px;
  --ispui-dropdown-min-height: 40px;
}
