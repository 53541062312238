:root {
  // ALL COLORS! From libs, and UX desires. @WARN don't forget about .less!
  // navigation menu text colors
  --isp-dark-gray-1: var(--isp-branding-dark-gray-1, #24262a);
  // top header background color
  --isp-dark-gray-2: var(--isp-branding-dark-gray-2, #24262a);
  // navigation menu text color
  --isp-dark-gray-4: var(--isp-branding-dark-gray-4, #24262a);
  // navigation menu expanded text color
  --isp-dark-gray-5: var(--isp-branding-dark-gray-5, #24262a);
  // top header tab panel background color
  --isp-dark-gray-3: var(--isp-branding-dark-gray-3, #585858);
  // global search background color
  --isp-dark-gray-6: var(--isp-branding-dark-gray-6, #585858);
  // main grey color 1
  --isp-light-gray-1: var(--isp-branding-light-gray-1, #d9d9d9);
  // global search inactive text color
  --isp-light-gray-21: var(--isp-branding-light-gray-21, #d9d9d9);
  // tabs panel not active color
  --isp-light-gray-2: var(--isp-branding-light-gray-2, #d9d9d9);
  // main grey color 2
  --isp-light-gray-3: var(--isp-branding-light-gray-3, #979797);
  // header secondary color
  --isp-light-gray-4: var(--isp-branding-light-gray-4, #979797);
  // main grey color 3
  --isp-light-gray-6: var(--isp-branding-light-gray-6, #f4f4f4);
  // global search color
  --isp-light-gray-22: var(--isp-branding-light-gray-22, #f4f4f4);
  // navigation menu background
  --isp-light-gray-7: var(--isp-branding-light-gray-7, #eeeeee);
  // navigation menu active background
  --isp-light-gray-8: var(--isp-branding-light-gray-8, #e6e6e6);
  // main grey color 4
  --isp-light-gray-9: var(--isp-branding-light-gray-9, #eeeeee);
  // breadcrumbs active color
  --isp-light-gray-10: var(--isp-branding-light-gray-10, #e6e6e6);
  // menu search bar background, selected tab item background, close-icon background
  --isp-light-gray-14: var(--isp-branding-light-gray-14, #f4f4f44d);
  // menu search bar hover, tab item hover, close-icon background
  --isp-light-gray-15: var(--isp-branding-light-gray-15, #f4f4f426);
  // menu search bar active, tab item active
  --isp-light-gray-16: var(--isp-branding-light-gray-16, #f4f4f466);
  // toggle color. Used only in ispmgr i think
  --isp-light-gray-20: var(--isp-branding-light-gray-20, #c2c2c2);
  // main scroll thum color. All scroll should be that color. There can be some exceptions
  --isp-gray-3: var(--isp-branding-gray-3, #5d6f7f);
  // main grey color 5
  --isp-gray-4: var(--isp-branding-gray-4, #727272);

  // color of statuses in table and selects
  --isp-yellow-3: var(--isp-branding-yellow-3, #f2a504);
  // buttons colors
  --isp-yellow-7: var(--isp-branding-yellow-7, #f8e71b);
  --isp-yellow-8: var(--isp-branding-yellow-8, #fffabb);
  --isp-yellow-9: var(--isp-branding-yellow-9, #faef66);
  // ux main yellow color
  --isp-yellow-6: var(--isp-branding-yellow-6, #ffe03a);
  // header top line PDZ text color
  --isp-yellow-10: var(--isp-branding-yellow-10, #ffe03a);
  // table row background
  --isp-yellow-11: var(--isp-branding-yellow-11, #fef0d8);

  // chat buble system
  --isp-orange-1: var(--isp-branding-orange-1, #ffe9cf);
  // notification color
  --isp-orange-2: var(--isp-branding-orange-2, #fff5ec);
  // password strength
  --isp-orange-3: var(--isp-branding-orange-3, #ff7a1a);
  // header top line PDZ text color
  --isp-orange-4: var(--isp-branding-orange-4, #ff7a1a);

  // main blue color
  --isp-blue-6: var(--isp-branding-blue-6, #1a76e2);
  // accent color in navigation menu
  --isp-blue-7: var(--isp-branding-blue-7, #1a76e2);
  // hover
  --isp-blue-8: var(--isp-branding-blue-8, #075bbe);
  // active
  --isp-blue-9: var(--isp-branding-blue-9, #1b88fe);
  // pdz graph color
  --isp-blue-10: var(--isp-branding-blue-10, #abbafc);
  // @TODO progressbar color. Shzeshanya promise me to replace color this color
  --isp-blue-12: var(--isp-branding-blue-12, #58bdf9);

  // main hover light blue
  --isp-light-blue-1: var(--isp-branding-light-blue-1, #e9f2ff);
  // accordion hover color, toolbar button active color
  --isp-light-blue-3: var(--isp-branding-light-blue-3, #daebff);
  // chat message background
  --isp-light-blue-7: var(--isp-branding-light-blue-7, #daebff);
  // list filter background color, table footer pagination button color, form scroller button active color
  --isp-light-blue-8: var(--isp-branding-light-blue-8, #f4f8fe);
  // main info notification color
  --isp-light-blue-9: var(--isp-branding-light-blue-9, #f3f9ff);
  // @TODO PDZ color. Shzeshanya promise me to replace this color
  --isp-light-blue-10: var(--isp-branding-light-blue-10, #c4e9ff);
  // @TODO progress bar color. Shzeshanya promise me to replace this color
  --isp-light-blue-14: var(--isp-branding-light-blue-14, #c4e9ff);
  // table 1n row selected color
  --isp-light-blue-11: var(--isp-branding-light-blue-11, #aeceff);
  // table 2n row selected color
  --isp-light-blue-13: var(--isp-branding-light-blue-13, #c1daff);
  // table union row hover color
  --isp-light-blue-12: var(--isp-branding-light-blue-12, #dbe8ff);

  // main text color
  --isp-dark-blue-2: var(--isp-branding-dark-blue-2, #1f364a);

  // second pink color, active
  --isp-pink-7: var(--isp-branding-pink-7, #ff487f);
  // main pink color
  --isp-pink-8: var(--isp-branding-pink-8, #db0444);
  // chat rate icons color
  --isp-pink-9: var(--isp-branding-pink-9, #ffcdcd);
  // hover
  --isp-pink-11: var(--isp-branding-pink-11, #ba0d41);
  // trial-notification background, and credit limit warning banner background
  --isp-light-pink-1: var(--isp-branding-light-pink-1, #fdf3f1);

  // main background color
  --isp-white: var(--isp-branding-white, #ffffff);
  // top header text color
  --isp-white-2: var(--isp-branding-white-2, #ffffff);
  // tabs active text color
  --isp-white-3: var(--isp-branding-white-3, #ffffff);
  // primary button text color
  --isp-white-4: var(--isp-branding-white-4, #ffffff);

  // for chat, system type messages
  --isp-light-green-1: var(--isp-branding-light-green-1, #e7fbe8);
  // for success password, success link hover
  --isp-green-2: var(--isp-branding-green-2, #65c84d);
  // main green color
  --isp-green-3: var(--isp-branding-green-3, #1d9052);
  // chat rate button
  --isp-green-4: var(--isp-branding-green-4, #d7ffcd);
  //  success link active
  --isp-green-5: var(--isp-branding-green-5, #1c9b7d);
  // table cyan text color
  --isp-cyan-1: var(--isp-branding-cyan-1, #0b9898);

  // table colored rows backgrounds
  --isp-red-opacity: var(--isp-branding-red-opacity, #e5499326);
  --isp-green-opacity: var(--isp-branding-green-opacity, #30ba9a33);
  --isp-blue-opacity: var(--isp-branding-blue-opacity, #0279c026);
  --isp-cyan-opacity: var(--isp-branding-cyan-opacity, #00bbbb40);

  // shadows
  --isp-text-color-main-opacity: var(
    --isp-branding-text-color-main-opacity,
    #1f364a80
  );

  // table kebab hover color
  // @TODO i.ablov use some blur function to add opacity to hex variable
  --isp-grey-opacity-2: var(--isp-branding-grey-opacity-2, #d2d2d280);

  // preloader background
  --isp-white-opacity: var(--isp-branding-white-opacity, #ffffffb3);
}
