:root {
  // ngispui-link or ngispui-vtab
  --isp-link__color_regular: var(--isp-blue-6);
  --isp-link__color_hover: var(--isp-blue-8);
  --isp-link__color_active: var(--isp-blue-9);

  // ngispui-checkbox
  --ngispui-checkbox__border-color: var(--isp-light-gray-3);
  --ngispui-checkbox__border-color-checked: var(--isp-blue-6);
  --ngispui-checkbox__icon-check: var(--isp-checkbox-checked-image);
  --ngispui-checkbox__border-color-disabled: var(--isp-light-gray-3);
  --ngispui-checkbox__icon-check-disabled: var(
    --isp-checkbox-checked-image-disabled
  );

  // notifications
  --ngispui-notification-group__top: auto;
  --ngispui-notification-group__bottom: 20px;
}

// @TODO e.polykov remove this when new notifications are available
// here override class for notification group from NgISPUpINotification
.ngispui-notification-wrapper {
  position: fixed;
  z-index: var(--isp-notification-wrapper-z-index, 100);
  top: var(--ngispui-notification-group__top, 40px);
  right: var(--ngispui-notification-group__right, 0);
  bottom: var(--ngispui-notification-group__bottom, auto);
  left: var(--ngispui-notification-group__left, auto);

  display: flex;

  overflow: hidden;
  align-items: flex-end;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;

  width: 380px;
  padding-right: 10px;

  .notification-group_override {
    .ngispui-notification-group {
      position: static;
      z-index: unset;

      overflow: unset;

      width: 100%;
      padding: 0;

      transition: height 250ms ease-out;
    }
  }
}
