:root {
  // ALL COLORS OF ISPUI-PROGRESSBAR v2.0.7
  --ispui-progress-bar__custom-color: linear-gradient(
    270deg,
    var(--isp-light-blue-14) 0%,
    var(--isp-blue-12) 25%,
    var(--isp-blue-6) 50%,
    var(--isp-blue-12) 75%,
    var(--isp-light-blue-14) 100%
  );
  --ispui-progress-bar__rainbow-animation-duration: 5s;
  --ispui-progress-bar__border-radius: 10px;
  --ispui-progress-bar__height: 7px;
}
