:root {
  // ALL COLORS OF ISPUI-CLOSE v2.0.4

  --ispui-close__dark-color: var(--isp-dark-blue-2);
  --ispui-close__dark-background-color_hover: var(--isp-light-gray-15);
  --ispui-close__dark-background-color_active: var(--isp-light-gray-14);

  // ispui-close gray theme don't used in our interface!
  --ispui-close__gray-color: var(--isp-light-gray-3);
  // --ispui-close__gray-background-color_hover: rgba(52, 74, 94, 0.2);
  // --ispui-close__gray-background-color_active: rgba(52, 74, 94, 0.4);

  --ispui-close__light-color: var(--isp-light-gray-6);
  --ispui-close__light-background-color_hover: rgba(255, 255, 255, 0.2);
  --ispui-close__light-background-color_active: rgba(255, 255, 255, 0.4);

  // @WARN ispui-close have focus state colors, that not customable!
}
