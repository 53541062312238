:root {
  // ALL COLORS OF ISPUI-SELECT v0.2.6-feature-inputv6-component.0

  --ispui-select-button__color: var(--isp-dark-blue-2);
  --ispui-select-button__arrow-border: 1px solid var(--isp-dark-blue-2);
  --ispui-select-button__border-focus: 1px solid var(--isp-blue-6);
  --ispui-select-button-search__border: 1px solid var(--isp-light-gray-1);
  --ispui-select-button-search__arrow-border: 1px solid var(--isp-dark-blue-2);
  --ispui-select-button-search__border-focus: 1px solid var(--isp-blue-6);
  --ispui-select-search__border: 1px solid var(--isp-light-gray-1);
  --ispui-select-button-legend__border: 1px solid var(--isp-light-gray-1);
  --ispui-select-button-legend__color: var(--isp-dark-blue-2);
  --ispui-select-button-legend__legend-color: var(--isp-gray-4);
  --ispui-select-button-legend__border-color-focus: var(--isp-text-color-link);
  --ispui-select-button-legend__legend-color-focus: var(--isp-text-color-link);
  --ispui-select-button-legend__arrow-border: 1px solid var(--isp-light-gray-3);
  --ispui-select-button-legend__br: var(--isp-border-radius);
  --ispui-select-button-legend__asterisk-color: var(--isp-pink-8);
  --ispui-select-button-line__color: var(--isp-dark-blue-2);
  --ispui-select-button-line__arrow-border: 1px solid var(--isp-dark-blue-2);
  --ispui-select-button-line__focus-color: var(--isp-blue-6);
  --ispui-select-button-chip__color: var(--isp-blue-6);
  --ispui-select-button-chipt__border: 1px solid var(--isp-blue-6);
  --ispui-select-button-chip__arrow-border: 1px solid var(--isp-blue-6);
  --ispui-select-button-chipt__border-focus: 2px solid var(--isp-blue-8);
  --ispui-select-list__scroll-bar-color: var(--isp-dark-blue-2);
  --ispui-select-list__scroll-track-color: var(--isp-light-gray-6);
  --ispui-select-list__item-color: var(--isp-dark-blue-2);
  --ispui-select-list__item-highlight-color: var(--isp-light-blue-1);
  --ispui-select-list__disabled-item-color: var(--isp-light-gray-1);
  --ispui-select-list__item-height: 35px;
  --ispui-select-list__max-height: 350px;
  --ispui-select-list__item-br: 3px;
  --ispui-select-list__item-checkbox-pl: 15px;
  --ispui-select-list__item-search-match-bg: var(--isp-yellow-6);
  --ispui-select-button-search__color: var(--isp-dark-blue-2);
  --ispui-button__fourth_text_regular: var(--isp-blue-6);
  --ispui-button__fourth_text_hover: var(--isp-blue-8);
  --ispui-button__fourth_text_active: var(--isp-blue-9);

  // this variables are not exist in ispui-select! Added manualy through builderParams! add them in library!
  --ispui-select-search__padding: 10px;
  --ispui-select-search__margin: 0;
  --ispui-select-list__margin: 0 0 0 10px;
  --ispui-select-list__padding: 0 10px 0 0;
  --ispui-select-list__min-width: calc(400px - 20px);
  --ispui-select-list__max-width: calc(400px - 20px);
}
